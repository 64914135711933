<template>
    <section class="section-full section-number" id="section-number">
    <div class="row">
      <div class="col-33">
        <div class="number-item">
          <img src="./../assets/icon_home_01.png" alt="icon home" class="icon-number" />
          <vue3-autocounter ref='counter1' :startAmount='0' :endAmount='50' :duration='3'  suffix='%'  :autoinit='false' />
          <h3>{{$t("home.numberTitle1")}}</h3>
          <p v-html="$t('home.numberText1')"></p>
        </div>
      </div>
      <div class="col-33">
        <div class="number-item">
          <img src="./../assets/icon_home_02.png" alt="icon home" class="icon-number" />
          <vue3-autocounter ref='counter2' :startAmount='0' :endAmount='80' :duration='3'  suffix='%'  :autoinit='false' />
          <h3>{{$t("home.numberTitle2")}}</h3>
          <p v-html="$t('home.numberText2')"></p>
        </div>
      </div>
      <div class="col-33">
        <div class="number-item">
          <img src="./../assets/icon_home_03.png" alt="icon home" class="icon-number" />
          <vue3-autocounter ref='counter3' :startAmount='0' :endAmount='27000' :duration='3' separator=''  :autoinit='false' />
          <h3>{{$t("home.numberTitle3")}}</h3>
          <p v-html="$t('home.numberText3')"></p>
        </div>
      </div>
      <div class="data-file">*{{ $t("home.data")}}</div>
    </div>

  </section>
</template>

<script>
export default {
    name : "NumberSection",
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll () {
            let el = document.getElementById('section-number');
            let rect = el.getBoundingClientRect();
            console.log("y: "+ rect.y);
            if(rect.y < 300) {
                this.$refs.counter1.start();
                this.$refs.counter2.start();
                this.$refs.counter3.start();
                 window.removeEventListener('scroll', this.handleScroll);
            }
        }
    }
}
</script>

<style scoped lang="scss">
.number-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  margin:40px auto;
}

.icon-number {
  width:150px;
}

.number-item h3 {
  color:$mainColor;
  text-align: center;
  font-size:25px;
  font-weight: 300;
  margin:10px 0;
}

.number-item p {
  text-align: center;
  color:#fff;
}

.number-item span {
  color:$mainColor;
  font-weight: 700;
  font-size:6rem;
}

.section-number {
  position:relative;
  background:url("./../assets/bg-home.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
}

.data-file {
  position:absolute;
  bottom:10px;
  right:10px;
  color:#fff;
}

@media screen and (max-width:768px) {

    .icon-number {
        width:120px;
    }

    .number-item h3 {
        font-size:20px;
    }

    .number-item span {
        font-size:60px;
    }

}
</style>