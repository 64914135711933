<template>
    <div class="banner-container" v-if="showBanner">
        <div class="banner-content" v-html="message"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Banner',
    props: {
      message: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        showBanner: true
      }
    },
    methods: {
      closeBanner() {
        this.showBanner = false;
      }
    }
  }
  </script>

<style scoped>
@keyframes marquee-desktop {
  0% {
    transform: translateX(-20%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes marquee-mobile {
  0% {
    transform: translateX(-65%);
  }
  100% {
    transform: translateX(100%);
  }
}

.banner-container {
  overflow: hidden;
  white-space: nowrap;
  background-color: transparent; /* Set background to transparent */
  color: #FFF;
  padding: 5px;
  position: relative;
  display: flex;
  align-items: center;
  height: 20px; /* Adjust height as needed */
  margin-top: 50px;
}

.banner-content {
  display: inline-block;
  width: 100%; /* Ensure the content spans the full width */
  animation: marquee-desktop 30s linear infinite;
}

@media (max-width: 768px) {
  .banner-content {
    animation: marquee-mobile 15s linear infinite;
  }
}
</style>
  
  <!-- <style scoped>
  @keyframes marquee {
    0% {
      transform: translateX(-65%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  .banner-container {
    overflow: hidden;
    white-space: nowrap;
    background-color: transparent; /* Set background to transparent */
    color: #FFF;
    padding: 5px;
    position: relative;
    display: flex;
    align-items: center;
    height: 20px; /* Adjust height as needed */
    margin-top: 50px;
  }
  
  .banner-content {
    display: inline-block;
    animation: marquee 30s linear infinite;
    width: 100%; /* Ensure the content spans the full width */
  }
  
  .banner-message {
    display: inline-block;
    white-space: nowrap; /* Prevent message from wrapping */
  }
  </style> -->
  