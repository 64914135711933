<template>
    <div class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <span class="close-btn" @click="closeModal">&times;</span>
        <p v-html="message"></p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Modal',
    props: {
      message: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        timer: null
      };
    },
    mounted() {
      this.startTimer();
    },
    beforeUnmount() {
      clearTimeout(this.timer);
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
      startTimer() {
        this.timer = setTimeout(() => {
          console.log('Closing modal after 5 seconds');
          this.closeModal();
        }, 10000); // 5000 milliseconds = 5 seconds
      }
    }
  }
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: black;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    max-width: 500px;
    width: 90%;
  }
  
  .close-btn {
    position: absolute;
    color: white;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
  }
  </style>
  
  
  

<!-- <template>
    <div class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <span class="close-btn" @click="closeModal">&times;</span>
        <p v-html="message"></p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Modal',
    props: {
      message: {
        type: String,
        required: true
      }
    },
    methods: {
      closeModal() {
        this.$emit('close');
      }
    }
  }
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: black;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    max-width: 500px;
    width: 90%;
  }
  
  .close-btn {
    position: absolute;
    color: white;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
  }
  </style> -->
  