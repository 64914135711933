<template>
  <modal v-if="showModal" :message="$t('home.banner_txt')" @close="showModal = false"></modal>

  <div class="sticky-container">
      <banner :message="$t('home.banner_txt')"></banner>
      </div>
  <slider></slider>
  <div class="sep"></div>
  <!-- <section class="section-video video1" @click="openVideo('https://youtu.be/HhkSzp6vWUM')">
    <div class="video-overlay"></div>
    <i class="fa-solid fa-circle-play play-icon"></i>
  </section>
  <div class="sep"></div>
  <section class="section-video video2" @click="openVideo('https://youtu.be/3TnxDqopsZE')">
    <div class="video-overlay"></div>
    <i class="fa-solid fa-circle-play play-icon"></i>
  </section> -->
  <div class="sep"></div>
  <section class="section-default">
    <div class="box">
      <div class="row">
        <div class="col-60">
          <img src="./../assets/home1.jpg" class="home-img" alt="home" />
        </div>
        <div class="col-40">
          <h2>{{$t("home.title1")}}</h2>
          <p v-html="$t('home.text1')"></p>
          <router-link class="btn" :to="{ name : 'Products'}">{{$t("home.btn")}}</router-link>
        </div>
      </div>
    </div>
  </section>
  <section class="section-default">
    <div class="box">
      <div class="row reverse">
        <div class="col-60">
          <img src="./../assets/home2.jpg" class="home-img" alt="home" />
        </div>
        <div class="col-40">
          <h2>{{$t("home.title2")}}</h2>
          <p v-html="$t('home.text2')"></p>
          <router-link class="btn" :to="{ name : 'Products'}">{{$t("home.btn")}}</router-link>
        </div>
      </div>
    </div>
  </section>
  <number-section></number-section>
  <blog-home></blog-home>
  <modal-video 
    :video="videoUrl" 
    v-if="displayVideo"
    v-on:close="closeVideo"
  ></modal-video>
</template>

<script>
import ModalVideo from "./../components/ModalVideo.vue";
import NumberSection from "./../components/NumberSection.vue";
import Slider from "./../components/Slider.vue";
import BlogHome from "./../components/BlogHome.vue";
import Banner from "./../components/Banner.vue";
import Modal from "./../components/Modal.vue"; //Modif Modal

export default {
  name: 'Home',
  components : {
    ModalVideo,
    NumberSection,
    Slider,
    BlogHome,
    Banner,
    Modal //Modif Modal
  },
  data() {
    return {
      showModal: true,  // Ensure the modal is shown when the page is loaded //Modif Modal
      videoUrl : null,
      displayVideo: false
    }
  },
  methods : {
    closeVideo() {
      this.displayVideo = false;
      this.videoUrl = null
    },
    openVideo(video) {
      this.displayVideo = true;
      this.videoUrl = video;
    }
  },
  mounted() { //Modif Modal
    // Optionally, show the modal when the component is mounted
    this.showModal = true; //Modif Modal
} //Modif Modal
}
</script>

<style scoped lang="scss">
.sticky-container {
  position: sticky;
  top: 80px; /* Adjust this value to match the height of your header */
  z-index: 1000; /* Ensure it's above other content */
}

.sep {
  height:2px;
  width:100%;
  background: $mainColor;
}

.section-video {
  height:400px;
  width:100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.video-overlay {
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background: rgba(0,0,0,0.8);
  transition: 0.5s;
}

.section-video:hover  .video-overlay{
  background: rgba(0,0,0,0.4);
}

.video1 {
  background: url("./../assets/bg_video-2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.video2 {
  background: url("./../assets/bg_video-3.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.play-icon {
  font-size:70px;
  color:$mainColor;
  position: relative;
  z-index: 9;
}

.section-default {
  padding:80px 0;
}

.home-img {
  width: 80%;
  margin:0 auto;
  display: block;
}

.col-40 {
  padding-left:20px;
  border-left:2px solid $mainColor;
}

.reverse .col-40 {
  padding-left:0;
  padding-right:20px;
  border-left:0;
  border-right:2px solid $mainColor;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.reverse .education-img{
  margin-top:20px;
}

.reverse h2, .reverse p {
  text-align: right;
}

h2 {
  color:$mainColor;
  font-size:35px;
  font-weight:400;
}

p {
  font-size:20px;
}

.btn {
  background:none;
  color:$mainColor;
  border:1px solid $mainColor;
}

.btn:hover {
  background:$mainColor;
  color:#fff;
}



@media screen and (max-width:768px) {
  .home-img, .reverse .home-img {
    width:450px;
    max-width: 90%;
    margin-bottom:40px;
    margin-top:80px;
  }

  p {
    font-size:15px;
  }

  .section-default  {
    padding:20px 0;
  }
}
</style>
