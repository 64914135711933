<template>
    <div class="slide">
        <div class="overlay"></div>
        <div class="box">
            <div class="row">
                <div class="col-50"></div>
                <div class="col-50">
                    <img src="./../../assets/slide1.png" alt="logo" class="logo" />
                    <div class="sep"></div>
                    <h1>{{ $t("slides.slide1.title1")}}<br /><span class="colored">{{ $t("slides.slide1.title2")}}</span></h1>
                    <div class="sep"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : "Slide1"
}
</script>

<style lang="scss" scoped>
    .slide {
        min-height: 100vh;
        width:100vw;
        background: url("./../../assets/slides/bg-slide1.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
    }

    .box {
        position: relative;
        z-index:9;
    }

    .overlay {
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        background:rgba(0,0,0,0.2);
    }

    .logo {
        width:200px;
        height:auto;
        position:relative;
        opacity: 0;
        top:-60px;
    }

    .sep {
        width: 400px;
        max-width: 50%;
        height:1px;
        background:$mainColor;
        margin:20px auto;
        position:relative;
        opacity: 0;
        right:-60px;
    }

    h1 {
        text-align: center;
        color:#fff;
        font-weight:300;
        font-size:30px;
        margin:0;
        line-height: 1.5em;
        position:relative;
        opacity: 0;
        left:-60px;
    }

    .carousel__slide--visible h1 {
        animation: slideLeft 5000ms ease-in 1s;
    }

    .carousel__slide--visible .sep {
        animation: slideRight 5000ms ease-in 1.5s;
    }

    .carousel__slide--visible .logo {
        animation: slideTop 5000ms ease-in;
    }

    @keyframes slideTop {
        0% {
            opacity: 0;
            top:-60px;
        }

        10% {
            opacity: 1;
            top: 0;
        }

        100% {
            opacity: 1;
            top: 0;
        }
    }
    
    @keyframes slideLeft {
        0% {
            opacity: 0;
            left:-60px;
        }

        10% {
            opacity: 1;
            left: 0;
        }

        100% {
            opacity: 1;
            left: 0;
        }
    }

    @keyframes slideRight {
        0% {
            opacity: 0;
            right:-60px;
        }

        10% {
            opacity: 1;
            right: 0;
        }

        100% {
            opacity: 1;
            right: 0;
        }
    }


</style>