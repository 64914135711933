<template>
    <div class="home-slider">
        <carousel :autoplay="5000" :wrap-around="true">
            <slide v-for="slide in 5" :key="slide">
                <div class="carousel__item">
                    <slide-1 v-if="slide === 1"></slide-1>
                    <!-- <slide-2 v-else-if="slide === 3"></slide-2> -->
                    <slide-3 v-else-if="slide === 2"></slide-3>
                    <slide-4 v-else-if="slide === 4"></slide-4>
                    <slide-5 v-else-if="slide === 5"></slide-5>
                    <slide-6 v-else></slide-6>
                </div>
            </slide>

            <template #addons>
            <Pagination />
            </template>
        </carousel>
    </div>
    <div class="slider-resp">
        <div class="overlay"></div>
        <div class="box">
            <img src="./../assets/slide1.png" alt="logo" class="logo" />
            <div class="sep"></div>
            <h1>{{ $t("slides.slide1.title1")}}<br /><span class="colored">{{ $t("slides.slide1.title2")}}</span></h1>
            <div class="sep"></div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide, Pagination } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import Slide1 from "./slides/Slide1.vue";
// import Slide2 from "./slides/Slide2.vue";
import Slide3 from "./slides/Slide3.vue";
import Slide4 from "./slides/Slide4.vue";
import Slide5 from "./slides/Slide5.vue";
import Slide6 from "./slides/Slide6.vue";

export default {
    name : "Slider",
    components : { 
        Carousel,
        Slide,
        Pagination,
        Slide1,
        // Slide2,
        Slide3,
        Slide4,
        Slide5,
        Slide6
    },
    
}
</script>

<style scoped lang="scss">
    h1 {
        color:$mainColor;
    }

    .carousel__slide{
        min-height: 100vh;
        width:100%;
        display:flex;
        justify-content: center;
        align-items: center;
    }

    .carousel__pagination {
        position:relative;
        top:-20px;
        margin-top:-45px;
    }

    .slider-resp {
        display: none;
        min-height: 100vh;
        width: 100%;
        background: url("./../assets/slides/bg-slide1.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .box {
        position: relative;
        z-index: 9;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .logo {
        width:200px;
        height:auto;
        position:relative;
        opacity: 0;
        top:-60px;
        animation: slideTop 5000ms ease-in;
    }

    .sep {
        width: 400px;
        max-width: 50%;
        height:1px;
        background:$mainColor;
        margin:20px auto;
        position:relative;
        opacity: 0;
        right:-60px;
        animation: slideRight 5000ms ease-in 1.5s;
    }

    h1 {
        text-align: center;
        color:#fff;
        font-weight:300;
        font-size:30px;
        margin:0;
        line-height: 1.5em;
        position:relative;
        opacity: 0;
        left:-60px;
        animation: slideLeft 5000ms ease-in 1s;
    }

    @keyframes slideTop {
        0% {
            opacity: 0;
            top:-60px;
        }

        10% {
            opacity: 1;
            top: 0;
        }

        100% {
            opacity: 1;
            top: 0;
        }
    }
    
    @keyframes slideLeft {
        0% {
            opacity: 0;
            left:-60px;
        }

        10% {
            opacity: 1;
            left: 0;
        }

        100% {
            opacity: 1;
            left: 0;
        }
    }

    @keyframes slideRight {
        0% {
            opacity: 0;
            right:-60px;
        }

        10% {
            opacity: 1;
            right: 0;
        }

        100% {
            opacity: 1;
            right: 0;
        }
    }

    @media screen and (max-width:1200px) {
        .home-slider {
            display: none;
        }

        .slider-resp {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
</style>