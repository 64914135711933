<template>
    <div class="slide">
        <div class="box">
            <h1 v-html="$t('slides.slide3.title1')"></h1>
            <div class="sep"></div>
            <div class="row">
                <div class="col-50">
                    <p class="anim-text">{{ $t("slides.slide3.text1")}}</p>
                    <p class="anim-text">{{ $t("slides.slide3.text2")}}</p>
                    <p class="anim-text">{{ $t("slides.slide3.text3")}}</p>
                </div>
                <div class="col-50">
                    <img src="./../../assets/slides/cypress.png" alt="cypress" class="anim-img" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : "Slide3"
}
</script>

<style lang="scss" scoped>
    .slide {
        min-height: 100vh;
        width:100vw;
        background: url("./../../assets/slides/bg-slide.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
    }

    .box {
        position: relative;
        z-index:9;
    }

    .row {
        align-items: center;
        width:1000px;
        margin:0 auto;
    }

    .anim-img {
        width:450px;
        max-width: 80%;
        position:relative;
        opacity: 0;
        right:-60px;
    }

    h1 {
        text-align: center;
        color:$mainColor;
        font-weight:400;
        font-size:40px;
        margin:0;
        line-height: 1.5em;
        position:relative;
        opacity: 0;
        right:-60px;
    }

    .sep {
        width: 350px;
        max-width: 50%;
        height:1px;
        background:#fff;
        margin:20px auto 80px auto;
        position:relative;
        opacity: 0;
        left:-60px;
    }

    .anim-text {
        color:#fff;
        text-align: left;
        font-size:25px;
        margin: 0 0 40px 0;
        opacity: 0;
        left:-60px;
    }

    .carousel__slide--visible h1 {
        animation: slideRight 5000ms ease-in 0.2s;
    }

    .carousel__slide--visible .sep {
        animation: slideLeft 5000ms ease-in 0.5s;
    }

    .carousel__slide--visible .anim-text:nth-child(1) {
        animation: slideLeft 5000ms ease-in 1s;
    }

    .carousel__slide--visible .anim-text:nth-child(2) {
        animation: slideLeft 5000ms ease-in 1.25s;
    }

    .carousel__slide--visible .anim-text:nth-child(3) {
        animation: slideLeft 5000ms ease-in 1.5s;
    }

    .carousel__slide--visible .anim-img {
        animation: slideRight 5000ms ease-in 2s;
    }

    @keyframes slideLeft {
        0% {
            opacity: 0;
            left:-60px;
        }

        10% {
            opacity: 1;
            left: 0;
        }

        100% {
            opacity: 1;
            left: 0;
        }
    }

    @keyframes slideRight {
        0% {
            opacity: 0;
            right:-60px;
        }

        10% {
            opacity: 1;
            right: 0;
        }

        100% {
            opacity: 1;
            right: 0;
        }
    }

</style>