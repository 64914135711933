<template>
    <div class="no-posts">
        <p>{{ $t("news.noPosts")}}</p>
    </div>
</template>

<script>
export default {
    name : "NoPosts"
}
</script>

<style lang="scss" scoped>
    p {
        color:#fff;
        font-size:18px;
        text-align: center;
    }
</style>