<template>
    <div class="slide">
        <div class="box">
            <h1 v-html="$t('slides.slide5.title1')"></h1>
            <div class="sep"></div>
            <div class="row">
                <div class="col-20">
                    <router-link :to="{name : 'PS' }" class="product product1">
                        <img src="./../../assets/slides/icones_cypress-1.png" alt="cypress" />
                        <h2 class="title-special"><span class="colored">C</span>ypress</h2>
                    </router-link>
                </div>   
                <div class="col-20">
                    <router-link :to="{name : 'PS' }" class="product product3">
                        <img src="./../../assets/slides/icones_kypho-1.png" alt="Kyphoplasty" />
                        <h2>Kyphoplasty<br />System</h2>
                    </router-link>
                </div>
                <div class="col-20">
                    <router-link :to="{name : 'VA' }" class="product product2">
                        <img src="./../../assets/slides/sycamore-device.png" alt="sycamore" />
                        <h2 class="title-special"><span class="colored">S</span>ycamore</h2>
                    </router-link>
                </div>
                <div class="col-20">
                    <router-link :to="{name : 'PS' }" class="product product4">
                        <img src="./../../assets/slides/image-cement-device.png" alt="cement" />
                        <h2>Cement</h2>
                    </router-link>
                </div>
                <div class="col-20">
                    <router-link :to="{name : 'PS' }" class="product product5">
                        <img src="./../../assets/slides/icones_oak-1.png" alt="oak" />
                        <h2 class="title-special"><span class="colored">O</span>ak</h2>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : "Slide5"
}
</script>

<style lang="scss" scoped>
    .slide {
        min-height: 100vh;
        width:100vw;
        background: url("./../../assets/slides/bg-slide.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
    }

    .box {
        position: relative;
        z-index:9;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h1 {
        text-align: center;
        color:$mainColor;
        font-weight:400;
        font-size:40px;
        margin:0 0 20px 0;
        line-height: 1.3em;
        position:relative;
        opacity: 0;
    }

    .sep {
        width: 500px;
        max-width: 50%;
        height:1px;
        background:$mainColor;
        margin:10px auto;
        position:relative;
        opacity: 0;       
    }

    .carousel__slide--visible h1 {
        animation: fade 5000ms ease-in 0.2s;
    }

    .carousel__slide--visible .sep {
        animation: fade 5000ms ease-in 0.5s;
    }

    h2 {
        color:#fff;
        font-size:30px;
        font-weight:300;
    }

    h2.title-special {
        font-size:60px;
        margin-top:-5px;
    }

    img {
        max-width: 90%;
        transition: 0.5s;
    }

    .row {
        margin-top: 40px;
    }

    .product {
        position: relative;
        top:-60px;
        opacity:0;
    }

    .product:hover img {
        transform: scale(1.2);
    }

    .carousel__slide--visible .product1 {
        animation: slideTop 5000ms ease-in 1s;
    }

    .carousel__slide--visible .product2 {
        animation: slideTop 5000ms ease-in 1.2s;
    }

    .carousel__slide--visible .product3 {
        animation: slideTop 5000ms ease-in 1.4s;
    }

    .carousel__slide--visible .product4 {
        animation: slideTop 5000ms ease-in 1.6s;
    }

    .carousel__slide--visible .product5 {
        animation: slideTop 5000ms ease-in 1.8s;
    }

    @keyframes fade {
        0% {
            opacity: 0;
        }

        10% {
            opacity: 1;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes slideTop {
        0% {
            opacity: 0;
            top:-60px;
        }

        10% {
            opacity: 1;
            top: 0;
        }

        100% {
            opacity: 1;
            top: 0;
        }
    }
</style>