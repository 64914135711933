<template>
    <div class="slide">
        <div class="box">
            <h1 v-html="$t('slides.slide4.title1')"></h1>
            <div class="sep"></div>
            <p v-html="$t('slides.slide4.text1')"></p>
            <div class="sep"></div>
            <img src="./../../assets/slide1.png" alt="logo" class="logo" />
        </div>
    </div>
</template>

<script>
export default {
    name : "Slide4"
}
</script>

<style lang="scss" scoped>
    .slide {
        min-height: 100vh;
        width:100vw;
        background: url("./../../assets/slides/bg-slide4.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
    }

    .box {
        position: relative;
        z-index:9;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h1 {
        text-align: center;
        color:$mainColor;
        font-weight:400;
        font-size:40px;
        margin:0 0 60px 0;
        line-height: 1.3em;
        position:relative;
        opacity: 0;
    }

    .sep {
        width: 500px;
        max-width: 50%;
        height:1px;
        background:$mainColor;
        margin:10px auto;
        position:relative;
        opacity: 0;
        right:-60px;
    }

    p {
        color:#fff;
        text-align: center;
        font-size:25px;
        margin: 20px auto;
        opacity: 0;
        right:-60px;
    }

    .carousel__slide--visible h1 {
        animation: fade 5000ms ease-in 0.2s;
    }

    .carousel__slide--visible .sep {
        animation: slideLeft 5000ms ease-in 1s;
    }

    .carousel__slide--visible p {
        animation: slideRight 5000ms ease-in 0.5s;
    }

    .carousel__slide--visible .logo {
        animation: fade 5000ms ease-in 2s;
    }

   .logo {
        width:150px;
        height:auto;
        position:relative;
        opacity: 0;
        margin-top:40px;
    }

        @keyframes slideLeft {
        0% {
            opacity: 0;
            left:-60px;
        }

        10% {
            opacity: 1;
            left: 0;
        }

        100% {
            opacity: 1;
            left: 0;
        }
    }

    @keyframes slideRight {
        0% {
            opacity: 0;
            right:-60px;
        }

        10% {
            opacity: 1;
            right: 0;
        }

        100% {
            opacity: 1;
            right: 0;
        }
    }

    @keyframes fade {
        0% {
            opacity: 0;
        }

        10% {
            opacity: 1;
        }

        100% {
            opacity: 1;
        }
    }
</style>